import React from 'react';
import Header from './components/Header';
import MarketInfo from './components/MarketInfo';
import TopBuys from './components/TopBuys';
import TransactionsSidebar from './components/TransactionsSidebar';
import { formatters } from './utils/formatters';
import PurchaseMap from './components/PurchaseMap';
import { useFirebaseData } from './hooks/useFirebaseData';

const TokenLiveTracker = () => {
  const { transactions, topBuys, marketInfo } = useFirebaseData();

  return (
    <div
      className="min-h-screen flex flex-col"
      style={{
        backgroundImage: 'url(/bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
      }}
    >
      <Header />

      <div className="flex-1 flex items-center justify-center p-6">
        <main className="max-w-4xl w-full">
          <PurchaseMap transactions={transactions} />
        </main>
      </div>

      <TransactionsSidebar
        transactions={transactions}
        formatTokenAmount={formatters.tokenAmount}
        formatWallet={formatters.wallet}
        formatTimestamp={formatters.timestamp}
      />
    </div>
  );
};

export default TokenLiveTracker;