import { useState, useEffect } from 'react';

// Aumentado el número de transacciones mock
const MOCK_TRANSACTIONS_COUNT = 200; // Aumentado de 30 a 200

const generateRandomWallet = () => {
  return Array.from({ length: 44 }, () => 
    '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'[
      Math.floor(Math.random() * 58)
    ]
  ).join('');
};

const generateMockData = () => {
  // Generar 200 transacciones iniciales
  const mockTransactions = Array.from({ length: 200 }, (_, i) => ({
    id: `tx-${i}`,
    signature: `sig-${i}`,
    timestamp: Date.now() - Math.floor(Math.random() * 86400000),
    txType: 'buy',
    tokenAmount: Math.random() * 100 + 1,
    traderPublicKey: Math.random().toString() + Date.now().toString()
  }));

  mockTransactions.sort((a, b) => b.timestamp - a.timestamp);

  return {
    transactions: mockTransactions,
    topBuys: [],
    marketInfo: {
      marketCapSol: 150000 + Math.random() * 50000,
      vSolInBondingCurve: 75000 + Math.random() * 25000,
      vTokensInBondingCurve: 1000000 + Math.random() * 500000
    }
  };
};

export const useFirebaseData = () => {
  const [transactions, setTransactions] = useState([]);
  const [topBuys, setTopBuys] = useState([]);
  const [marketInfo, setMarketInfo] = useState({
    marketCapSol: 0,
    vSolInBondingCurve: 0,
    vTokensInBondingCurve: 0
  });

  useEffect(() => {
    // Generación inicial de datos
    const mockData = generateMockData();
    setTransactions(mockData.transactions);
    setTopBuys(mockData.topBuys);
    setMarketInfo(mockData.marketInfo);

    // Actualización periódica
    const transactionUpdateInterval = setInterval(() => {
      const newTransaction = {
        id: `tx-${Date.now()}`,
        signature: `sig-${Date.now()}`,
        timestamp: Date.now(),
        txType: 'buy',
        tokenAmount: Math.random() * 100 + 1,
        traderPublicKey: generateRandomWallet() + Date.now().toString()
      };

      setTransactions(prev => [newTransaction, ...prev.slice(0, MOCK_TRANSACTIONS_COUNT - 1)]);
    }, 2000); // Actualización más frecuente

    return () => {
      clearInterval(transactionUpdateInterval);
    };
  }, []);

  return { transactions, topBuys, marketInfo };
};