import React, { useState, useCallback, useEffect } from 'react';
import { Search, Twitter } from 'lucide-react';
import { Card, CardHeader, CardContent } from './ui/card';
import { Input } from "./ui/input.jsx"
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup
} from "react-simple-maps";

const PurchaseMap = ({ transactions }) => {
  const [searchWallet, setSearchWallet] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [position, setPosition] = useState({ coordinates: [0, 20], zoom: 1 });
  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    fetch('https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json')
      .then(response => response.json())
      .then(topology => {
        const { features } = require('topojson-client').feature(topology, topology.objects.countries);
        setMapData({ type: "FeatureCollection", features });
      })
      .catch(error => console.error('Error loading map:', error));
  }, []);

  const generateLocation = useCallback((index) => {
    // Array de coordenadas de principales masas terrestres
    const landCoordinates = [
      // Norte América
      { minLat: 30, maxLat: 70, minLong: -140, maxLong: -60 },
      // Sur América
      { minLat: -50, maxLat: 10, minLong: -80, maxLong: -35 },
      // Europa
      { minLat: 35, maxLat: 70, minLong: -10, maxLong: 40 },
      // África
      { minLat: -35, maxLat: 35, minLong: -20, maxLong: 50 },
      // Asia
      { minLat: 0, maxLat: 70, minLong: 60, maxLong: 140 },
      // Oceanía
      { minLat: -40, maxLat: 0, minLong: 110, maxLong: 180 }
    ];
  
    // Seleccionar una región basada en el índice
    const regionIndex = index % landCoordinates.length;
    const region = landCoordinates[regionIndex];
  
    // Generar coordenadas dentro de la región seleccionada
    const latitude = region.minLat + (Math.random() * (region.maxLat - region.minLat));
    const longitude = region.minLong + (Math.random() * (region.maxLong - region.minLong));
  
    return [longitude, latitude];
  }, []);

  const handleSearch = useCallback((wallet) => {
    setSearchWallet(wallet);
    if (!wallet) {
      setSelectedLocation(null);
      return;
    }

    const foundTx = transactions?.find(tx =>
      tx.traderPublicKey.toLowerCase().includes(wallet.toLowerCase())
    );

    if (foundTx) {
      const coordinates = generateLocation(parseInt(foundTx.id.split('-')[1]));
      setSelectedLocation(foundTx);
      setPosition(prev => ({
        ...prev,
        coordinates: coordinates,
        zoom: 4
      }));
    } else {
      setSelectedLocation(null);
    }
  }, [transactions, generateLocation]);

  if (!mapData) {
    return (
      <Card className="w-full max-w-4xl mx-auto">
        <CardContent className="p-8 text-center">
          Loading map...
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-4xl mx-auto">
      <CardHeader className="flex flex-row items-center justify-between">
        <div className="relative w-full max-w-sm">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
          <Input
            type="text"
            placeholder="Search by wallet address..."
            className="pl-8"
            value={searchWallet}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </CardHeader>
      <CardContent>
        <div className="aspect-[16/9] w-full rounded-lg overflow-hidden border border-gray-200">
          <ComposableMap
            projection="geoEqualEarth"
            projectionConfig={{
              scale: 150
            }}
            style={{
              background: '#a4a4eb'
            }}
          >
            <ZoomableGroup
              zoom={position.zoom}
              center={position.coordinates}
              onMoveEnd={setPosition}
            >
              <rect
                x={-1000}
                y={-1000}
                width={2000}
                height={2000}
                fill="#a4a4eb"
              />
              <Geographies geography={mapData}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="transparent"
                      stroke="#D1D5DB"
                      strokeWidth={0.5}
                      style={{
                        default: {
                          fill: "transparent",
                          stroke: "#D1D5DB",
                          strokeWidth: 0.5,
                          outline: "none"
                        },
                        hover: {
                          fill: "rgba(209, 213, 219, 0.1)",
                          stroke: "#E5E7EB",
                          strokeWidth: 1,
                          outline: "none"
                        }
                      }}
                    />
                  ))
                }
              </Geographies>

              {transactions?.map((tx, index) => {
                const coordinates = generateLocation(index);
                const isSelected = selectedLocation?.id === tx.id;

                return (
                  <Marker
                    key={tx.id}
                    coordinates={coordinates}
                  >
                    <circle
                      r={isSelected ? 8 : 4}
                      fill={isSelected ? "#EF4444" : "#DC2626"}
                      stroke={isSelected ? "#FEE2E2" : "#FEE2E2"}
                      strokeWidth={2}
                      className={`
                        cursor-pointer
                        transition-all duration-300
                        ${isSelected ? 'animate-pulse' : ''}
                      `}
                      onClick={() => {
                        setSelectedLocation(tx);
                        setSearchWallet(tx.traderPublicKey);
                        setPosition(prev => ({
                          ...prev,
                          coordinates: coordinates,
                          zoom: 4
                        }));
                      }}
                    />
                  </Marker>
                );
              })}
            </ZoomableGroup>
          </ComposableMap>
        </div>

        {selectedLocation && (
          <div className="mt-4 p-4 bg-gray-50 rounded-lg">
            <h3 className="font-medium">Purchase Details</h3>
            <div className="mt-2 space-y-1 text-sm">
              <p>Wallet: {selectedLocation.traderPublicKey}</p>
              <p>Amount: {selectedLocation.tokenAmount.toFixed(2)} tokens</p>
              <p>Time: {new Date(selectedLocation.timestamp).toLocaleString()}</p>
              <div className="mt-4">
                <button
                  onClick={() => {
                    const tweetText = `🪖 It's time for a change. Join the resistance, save the trenches.\nSign up too!🪖\nJust made a purchase of ${selectedLocation.tokenAmount.toFixed(2)} tokens!\n\n #SST`;
                    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`, '_blank');
                  }}
                  className="flex items-center gap-2 px-4 py-2 text-white rounded-md bg-[#1DA1F2] hover:bg-[#1a8cd8] transition-colors"
                >
                  <Twitter size={16} />
                  Share on Twitter
                </button>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PurchaseMap;