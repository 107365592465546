import React from 'react';
import { ArrowUpRight, ArrowDownLeft } from 'lucide-react';

const TransactionsSidebar = ({ transactions, formatTokenAmount, formatWallet, formatTimestamp }) => {
  return (
    <div className="w-full border-t border-gray-200 bg-white">
      <div className="container mx-auto">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-bold">Live Transactions</h2>
          <div className="text-sm text-gray-500">
            Last 30 transactions
          </div>
        </div>
        <div className="p-4 overflow-x-auto">
          <div className="flex gap-3">
            {transactions.map((tx) => (
              <div 
                key={tx.id}
                className={`flex-shrink-0 w-64 p-4 rounded-lg ${
                  tx.txType === 'buy' ? 'bg-green-50' : 'bg-red-50'
                } hover:brightness-95 transition-colors`}
              >
                <div className="flex items-center gap-2">
                  {tx.txType === 'buy' ? (
                    <ArrowDownLeft className="text-green-500" size={20} />
                  ) : (
                    <ArrowUpRight className="text-red-500" size={20} />
                  )}
                  <span className="font-medium">
                    {tx.txType === 'buy' ? 'Buy' : 'Sell'}
                  </span>
                </div>
                <div className="mt-2 text-sm">
                  <div>{formatTokenAmount(tx.tokenAmount)} tokens</div>
                  <div className="text-gray-500">
                    by {formatWallet(tx.traderPublicKey)}
                  </div>
                  <div className="text-gray-500 text-xs mt-1">
                    {formatTimestamp(tx.timestamp)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsSidebar;